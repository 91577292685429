<template>
    <div class="common_content news_box">
        <div class="news_top_title">
            企业新闻   /   CORPORATE NEWS
        </div>
        <div class="line_b"></div>
        <div class="news_content">
            <ul id="ul_p">
                <li v-for="(news,index) in companyNewList" :key="index" @click="handleToDetail(news)">
                    <div class="img_box"><img :src="news.small_image_url"/></div>
                    <h4 :title="news.title">{{news.title}}</h4>
                    <p :title="news.description">{{news.description}} </p>
                    <div class="flex_box">
                        <div class="flex_item more">MORE</div>
                        <!-- <div class="flex_item date_text">{{ news.updated_at | formatDate}} <span>/ {{news.updated_at | formatMoth}}</span></div> -->
                        <div class="flex_item date_text"><span>{{news.updated_at | formatDate}}</span></div>
                    </div>
                </li>
            </ul>
            <div class="pagination_box">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="handleCurrentChange"
                    :current-page="page.currentPage"
                    :page-size="page.pageSize"	
                    :total="page.total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            companyNewList: [],
            page:{
                currentPage: 1,
                pageSize: 6,
                total:0,
            }
        }
    },
    filters:{
        formatMoth(val) {
            return new Date(val).getMonth() + 1
        },
        // formatDate(val) {
        //     return new Date(val).getDate()
        // },
        formatDate(val) {
            return  new Date(val).getFullYear()+'/'+ (new Date(val).getMonth()+1) +'/'+ new Date(val).getDate()
        },
    },
    created() {
        this.getCompanyNews();
    },
    methods:{
        handleCurrentChange(currentPage) {
            this.page.currentPage = currentPage;
            this.getCompanyNews();
        },
         // 获取新闻
        getCompanyNews() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let cond= {
                params:{
                    top_category_id: 1,
                    page: this.page.currentPage,
                    per_page: this.page.pageSize
                }
            };
            this.$http.get(this.$apiUrl.common.getNews,cond).then((res) => {
                this.companyNewList = res.paginate.data;
                this.page.total = res.paginate.total;
                 let dom = document.getElementById('ul_p')
                let domli = dom.getElementsByTagName('li')
                for (let i = 0; i < domli.length; i++) {
                    domli[i].classList.remove('animated');
                    domli[i].classList.remove('fadeInUpBig');
                    
                }
                setTimeout(() => {
                    this.$nextTick(()=>{
                        for (let i = 0; i < domli.length; i++) {
                            let delay = 'delay-' + i + 's';
                            domli[i].classList.add('animated');
                            domli[i].classList.add('fadeInUpBig');
                            domli[i].classList.add(delay);
                            
                        }
                    })
                }, 100);
                loading.close();
            }).catch(()=>{
                loading.close();
            });
        },
        handleToDetail(item) {
            this.$router.push({name: 'detail', query:{id: item.id}})
        },
    }
}
</script>
<style scoped>
.common_content{
    margin-left: 370px;
    margin-top: 4vh;
}
.news_box{
    margin-right: 108px;
}
.news_top_title{
    font-size: 15px;
font-family: AlibabaPuHuiTiL;
font-weight: bolder;
color: #000000;
text-align: center;
}
.line_b{
    height: 2px;
background: #F2F2F2;
margin: 36px 0 26px 0;
}
.news_content{
    
}
.news_content ul{
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
}
.news_content ul li{
    flex: 1;
    max-width: 398px;
        margin-bottom: 20px;
        padding: 20px;
        margin-right: 34px;
}
.news_content ul li:hover{
    /* box-shadow: 0px 0px 9px 3px rgb(207 207 207 / 62%); */
    box-shadow: 0 0 90px -18px rgb(0 0 0 / 25%);
    background-color: #fff;
    cursor: pointer;
}
.news_content ul li h4{
font-size: 19px;
font-family: AlibabaPuHuiTiM;
font-weight: bolder;
color: #414141;
line-height: 29px;
padding: 24px 0 24px 0;
margin: 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.news_content ul li p{
font-size: 15px;
font-family: AlibabaPuHuiTiL;
font-weight: 400;
color: #A6A6A6;
line-height: 29px;
margin: 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.img_box{
    width: 398px;
height: 143px;
}
.img_box img{
width: 100%;
max-height: 143px;
}
.flex_box{
display: flex;
justify-content: space-between;
    margin-top: 50px;
    border-bottom: 2px solid #F2F2F2;
    padding-bottom: 10px;
}
.flex_item{
    
}
.flex_item.more{
font-size: 13px;
font-family: AlibabaPuHuiTiH;
font-weight: bolder;
color: #ACACAC;
line-height: 29px;
}
.flex_item.date_text{
font-size: 25px;
font-family: AlibabaPuHuiTiH;
font-weight: bolder;
color: #EE660E;
line-height: 29px;
}
.flex_item.date_text span{
font-size: 15px;
color: #B4B4B4;
}
.pagination_box{
        margin-top: 4px;
}
.animated.delay-1s {
    -webkit-animation-delay: .3s;
    animation-delay: .3s;
}
.animated.delay-2s {
    -webkit-animation-delay: .6s;
    animation-delay: .6s;
}
.animated.delay-3s {
    -webkit-animation-delay: .9s;
    animation-delay: .9s;
}
.animated.delay-4s {
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
}
.animated.delay-5s {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
}
.animated.delay-6s {
    -webkit-animation-delay: 1.8s;
    animation-delay: 1.8s;
}
</style>
<style>
.el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #ee660e;
    color: #ffffff;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover{
    color: #ee660e;
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover{
    color: #ffffff;
}
</style>